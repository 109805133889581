import logo from './logo.svg';
import './App.css';
import { useEffect } from 'react';
function App() {
  console.log("window.location.path",window.location);
  useEffect(() =>{
  //   const roomId=window.location.href.split("?")
  //   console.log("room",roomId);
  //   let url=`https://newpmt.brigosha.com/meet/brigosha?${roomId[1]}`
  //   if(roomId){
  //  window.location.href=url
    const roomId = window.location.pathname.split("brigosha/")
    let url=`https://newpmt.brigosha.com/meet/brigosha?${roomId[1]}`
    if(roomId){
      window.location.href=url
    }
  },[])
  return (
    <div className="App">
     <div>
      Redirecting ...
     </div>
    </div>
  );
}

export default App;